'use strict';
jQuery(document).ready(function ($) {
  function load_js() {
    var head = document.getElementsByTagName('head')[0];
    var script = document.createElement('script');
    script.src = './assets/js/dist/script.min.js';
    head.appendChild(script);
  }

  $(window).keydown(function (event) {
    if (event.keyCode == 13) {
      event.preventDefault();
      return false;
    }
  });
  document?.getElementById('amount')?.addEventListener('change', (event) => {
    event.target.value = parseFloat(event.target.value).toFixed(2);
  });

  $('#payment_form')
    // to prevent form from submitting upon successful validation
    .on('submit', function (ev) {
      ev.preventDefault();

      $('#payment_form button').attr('disabled', 'true');
      //set cursor as loading icon
      $('body').css('cursor', 'wait');

      var that = $('#payment_form'),
        url = that.attr('action'),
        method = that.attr('method'),
        data = {};
      that.find('[name]').each(function () {
        var that = $(this),
          name = that.attr('name'),
          value =
            that.is(':radio') && !that.is(':checked') ? data[name] : that.val();

        data[name] = value;
      });
      console.log(data);

      $.ajax({
        url: url,
        type: method,
        data: data,
        success: function (response) {
          let result = JSON.parse(response);

          //set cursor back to normal
          $('body').css('cursor', 'default');
          //redirect to the payment page
          window.location.href = result;
        },
        error: function (response) {
          $('body').css('cursor', 'default');
          alert(response.responseText);
          $('#payment_form button').removeAttr('disabled');
        },
      });
    });
});
